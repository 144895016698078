<template>
  <v-card>
    <v-card-title class="text-h6">
      Định mức: {{ item.tracking_id }}
      <v-spacer></v-spacer>
      <v-btn
        class="mr-2"
        color="success"
        v-if="item.production_status === 3"
        @click="publish"
      >
        {{ $t("labels.publish") }}
      </v-btn>
      <v-btn color="error" outlined @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row class="mb-8">
        <v-col cols="6">
          <v-btn
            :color="tab === 'plan' ? 'primary' : 'default'"
            block
            @click="changeTab('plan')"
          >
            Nguyên liệu
            <v-icon v-if="item.production_status < 2">mdi-close</v-icon>
            <v-icon v-else>mdi-check</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            :color="tab === 'marker' ? 'primary' : 'default'"
            block
            @click="changeTab('marker')"
            :disabled="isDisabledMarker"
          >
            Định mức
            <v-icon v-if="item.production_status < 3">mdi-close</v-icon>
            <v-icon v-else>mdi-check</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="items.length > 0">
        <RequirementQuantitativePlan
          v-if="tab === 'plan'"
          :item="item"
          :items="items"
          @confirmMaterial="confirmMaterial"
        />
        <RequirementQuantitativeMarker
          v-if="tab === 'marker'"
          :item="item"
          :items="items"
          @confirmMarker="confirmMarker"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "RequirementQuantitative",
  components: {
    RequirementQuantitativePlan: () =>
      import("@/components/goods_production/RequirementQuantitativePlan"),
    RequirementQuantitativeMarker: () =>
      import("@/components/goods_production/RequirementQuantitativeMarker"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    tab: "plan",
    items: [],
  }),
  computed: {
    isDisabledMarker() {
      return this.item.production_status < 2;
    },
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    changeTab(tab) {
      this.tab = tab;
    },

    getList: debounce(function () {
      httpClient
        .post("/goods-production-requirement-detail", {
          id_goods_production_requirement: this.item.id,
        })
        .then(({ data }) => {
          this.items = [...data];
        });
    }, 500),

    async publish() {
      if (
        !confirm(
          this.$t(`messages.goods_production_requirement_confirm`, {
            tracking: this.item.tracking_id,
          })
        )
      ) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/goods-production-requirement-publish", {
          id_goods_production_requirement: this.item.id,
        });
        this.isLoading = false;
        const msg = this.$t("messages.update_success");
        this.$vToastify.success(msg);
        this.$emit("publish", true);
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    confirmMaterial() {
      this.$emit("confirmMaterial", true);
    },

    confirmMarker() {
      this.$emit("confirmMarker", true);
    },
  },
};
</script>

<style scoped></style>
